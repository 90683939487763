import './Heading.css';
import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';

export const H3 = forwardRef<HTMLHeadingElement, UI.Heading.Props>(function H3(
  {children, className, tone, size, ...attributes},
  ref,
) {
  const classes = classNames('Heading', className, {
    'tone-default': tone == null || tone === 'default',
    'tone-danger': tone === 'danger',
    'tone-info': tone === 'info',
    'tone-neutral': tone === 'neutral',
    'tone-success': tone === 'success',
    'tone-warning': tone === 'warning',
    'size-xs': size === 'xs',
    'size-sm': size === 'sm',
    'size-md': size === 'md',
    'size-lg': size === 'lg',
    'size-xl': size === 'xl',
    'size-2xl': size === '2xl',
  });

  return (
    <h3 {...attributes} className={classes} ref={ref}>
      {children}
    </h3>
  );
});
