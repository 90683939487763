import {useService} from '@cindedi/react-application';
import {A} from '@cindedi/ui/components/A';
import {Card} from '@cindedi/ui/components/Card';
import {H2} from '@cindedi/ui/components/Heading/H2';
import {H3} from '@cindedi/ui/components/Heading/H3';
import {Slot} from '@cindedi/ui/components/Slot';
import {Span} from '@cindedi/ui/components/Span';
import type {MetaFunction, LoaderFunction} from '@remix-run/node';
import {json} from '@remix-run/node';
import {Link, useLoaderData} from '@remix-run/react';
import {PostList} from '~/domains/blog/components/PostList';
import {PublicLayout} from '~/domains/organization/components/PublicLayout';

export interface LandingPageLoaderData {
  links: Organization.PublicLinkDto[];
  supportAgencies: Organization.PublicSupportAgencyDto[];
  posts: Blog.PublicPostDto[];
}

export const meta: MetaFunction = function LandingPageMeta() {
  return [
    {title: 'Principal - Cindedi'},
    {
      name: 'description',
      content:
        'CINDEDI: referência em pesquisa sobre desenvolvimento humano e educação infantil. Conheça nossas pesquisas e contribua para a formação profissional!',
    },
  ];
};

export const loader: LoaderFunction = async function LandingPageLoader({context: {use}}) {
  const Post = use('Post');
  const Link = use('Link');
  const SupportAgency = use('SupportAgency');

  const links = await Link.all();
  const supportAgencies = await SupportAgency.all();
  const posts = await Post.all({where: {published: true}});

  return json({
    links: links.sortBy('order').slice(0, 5),
    supportAgencies: supportAgencies.sortBy('order').slice(0, 5),
    posts: posts.sortByDesc('publishedAt').all(),
  });
};

export default function LandingPageView() {
  const data = useLoaderData<LandingPageLoaderData>();
  useService('organization/titlebar').setTitle('Notícias');

  return (
    <PublicLayout>
      <div className="flex gap-4 max-w-full flex-col lg:flex-row">
        <div className="flex flex-col flex-1 gap-2 pt-4 max-w-full">
          <div className="flex justify-end">
            <Link
              to="/noticias"
              className="text-sm text-primary-12 font-semibold leading-7 hover:text-primary-11"
            >
              Ver todas
            </Link>
          </div>
          <PostList posts={data.posts} />
        </div>
        <div className="flex flex-col w-full lg:w-72 gap-4">
          <Card aria-label="Lista de links relevantes">
            <Slot name="title">
              <H2 className="pl-4 pt-4">Links</H2>
            </Slot>

            <Slot name="action">
              <Link to="/links" className="pr-4 pt-4 text-sm text-primary-12 hover:text-primary-11">
                Ver todos
              </Link>
            </Slot>
            <div className="flex flex-col p-4 gap-4">
              {data.links.map((link) => (
                <A target="_blank" key={link.id} href={link.url}>
                  <div className="flex flex-col">
                    <span>{link.name}</span>
                    <Span size="sm" className="truncate">
                      {link.url}
                    </Span>
                  </div>
                </A>
              ))}
            </div>
          </Card>
        </div>
      </div>
      <div>
        <H3 className="pl-4 pt-4">Agências de apoio</H3>
        <ul role="list" className="flex items-center gap-4 p-4">
          {data.supportAgencies.map((agency) => (
            <li key={agency.id}>
              <A target="_blank" href={agency.url}>
                <img src={agency.cover?.url} alt={agency.name} className="max-h-20 max-w-32" />
              </A>
            </li>
          ))}
        </ul>
      </div>
    </PublicLayout>
  );
}
